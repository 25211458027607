<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend style="width: 48%" variant="light">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  >RECOMMENDATION
                </b-button>
              </b-input-group-prepend>

              <b-input-group-append is-text style="display: grid; width: 52%">
                <b-form-checkbox-group
                  class="w-100"
                  :disabled="
                    isModulSpecialistsDigital &&
                    dataPropsRecovery.statuss == 'PENDING'
                      ? false
                      : isModulSpecialist
                      ? false
                      : true
                  "
                  v-model="dataPropsRecovery.selectedRecommendations"
                >
                  <b-form-checkbox :value="1">
                    <span style="margin-left: 5px">NCR</span></b-form-checkbox
                  >
                  <b-form-checkbox :value="2">
                    <span style="margin-left: 5px">Application</span>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="12"
            v-if="dataPropsRecovery.selectedRecommendations.includes(2)"
            class="pt-2"
          >
            <b-input-group>
              <b-input-group-prepend style="width: 48%" variant="light">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  >TYPE OF APPLICATION:</b-button
                >
              </b-input-group-prepend>

              <b-form-select
                :disabled="
                  isModulSpecialistsDigital &&
                  dataPropsRecovery.statuss == 'PENDING'
                    ? false
                    : isModulSpecialist
                    ? false
                    : true
                "
                :style="
                  dataPropsRecovery.error_obs_charge == true
                    ? 'border-color: red;'
                    : ''
                "
                class="class-campo-text input-form"
                v-model="dataPropsRecovery.idcharge"
              >
                <b-form-select-option
                  v-for="(item, index) in dataPropsRecovery.charges"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.description }}
                </b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >RESULTS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="formRecov.results"
              :disabled="
                isModulSpecialistsDigital &&
                dataPropsRecovery.statuss == 'PENDING'
                  ? false
                  : isModulSpecialist
                  ? false
                  : true
              "
              :style="
                formRecov.results_error == true ? 'border-color: red;' : ''
              "
              @input="validateInput()"
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >COMMENTS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="formRecov.comments"
              :disabled="
                isModulSpecialistsDigital &&
                dataPropsRecovery.statuss == 'PENDING'
                  ? false
                  : isModulSpecialist
                  ? false
                  : true
              "
              :style="
                formRecov.comments_error == true ? 'border-color: red;' : ''
              "
              @input="validateInput()"
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12" class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >NEXT STEPS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="formRecov.next"
              :disabled="
                isModulSpecialistsDigital &&
                dataPropsRecovery.statuss == 'PENDING'
                  ? false
                  : isModulSpecialist
                  ? false
                  : true
              "
              :style="formRecov.next_error == true ? 'border-color: red;' : ''"
              @input="validateInput()"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ["dataPropsRecovery", "formRecov"],
  data() {
    return {};
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    validateInput() {
      this.formRecov.results.length > 0
        ? (this.formRecov.results_error = false)
        : (this.formRecov.results_error = true);
      this.formRecov.comments.length > 0
        ? (this.formRecov.comments_error = false)
        : (this.formRecov.comments_error = true);
      this.formRecov.next.length > 0
        ? (this.formRecov.next_error = false)
        : (this.formRecov.next_error = true);
    },
  },
  created() {
    this.validateInput();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModulSpecialist() {
      return this.moduleId == 11 ? true : false;
    },
    isModulSpecialistsDigital() {
      return this.moduleId == 25 ? true : false;
    },
  },
};
</script>
