export default [
    {
        key: "name",
        label: "Name",
        //thStyle: { width: "20%" },
        thClass: "text-center",
        tdClass: "text-center",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "size",
        label: "Size",
        //thStyle: { width: "20%" },
        thClass: "text-center",
        tdClass: "text-center",
        class: "text-left",
        sortable: false,
        visible: true,
        },
    {
        key: "bureu",
        label: "BUREU",
        //thStyle: { width: "20%" },
        thClass: "text-center",
        tdClass: "text-center",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "upload_at",
        label: "Uploaded At",
        //thStyle: { width: "20%" },
        thClass: "text-center",
        tdClass: "text-center",
        class: "text-left",
        sortable: false,
        visible: true,
    }
];