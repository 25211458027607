<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend style="width: 48%" variant="light">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  >RECOMMENDATION
                </b-button>
              </b-input-group-prepend>

              <b-input-group-append is-text style="display: grid; width: 52%">
                <b-form-checkbox-group
                  class="w-100"
                  :disabled="
                    (isModulSpecialistsDigital &&
                      dataPropsHas.statuss == 'PENDING') ||
                    isModulSpecialist
                      ? false
                      : true
                  "
                  v-model="dataPropsHas.selectedRecommendations"
                >
                  <b-form-checkbox :value="1"
                    ><span style="margin-left: 5px">NCR</span></b-form-checkbox
                  >
                  <b-form-checkbox :value="2"
                    ><span style="margin-left: 5px"
                      >Application</span
                    ></b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row v-if="dataPropsHas.selectedRecommendations.includes(2)">
          <b-col md="12" class="mt-2">
            <b-input-group>
              <b-input-group-prepend style="width: 48%" variant="light">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  >TYPE OF APPLICATION:</b-button
                >
              </b-input-group-prepend>
              <b-form-select
                :disabled="
                  isSpecialist && dataPropsHas.statuss == 'PENDING'
                    ? false
                    : isModulSpecialist
                    ? false
                    : true
                "
                :style="
                  dataPropsHas.error_obs_charge == true
                    ? 'border-color: red;'
                    : ''
                "
                class="class-campo-text input-form"
                v-model="dataPropsHas.idcharge"
              >
                <b-form-select-option
                  v-for="(item, index) in dataPropsHas.charges"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.description }}
                </b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >COMMENTS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs2"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >IN THIS CASE IF THE CUSTOMER DID NOT RECEIVE LETTER THESE ARE THE
              INDICATIONS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs1"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >INFORMATION WAS RECOVERED AND THE RESULTS ARE AS
              FOLLOWS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs2"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >CLIENT RECOMENDATIONS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs3"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >ADVISOR RECOMENDATIONS:</b-button
            >
            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs4"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="mt-2">
            <b-button
              :variant="isDarkSkin ? 'primary' : 'light'"
              class="w-100 disableBtn"
              >NEXT STEPS:</b-button
            >

            <b-form-textarea
              style="margin-top: 3px"
              placeholder="Enter something..."
              v-model="dataPropsHas.obs5"
              :disabled="
                isSpecialist && dataPropsHas.statuss == 'PENDING' ? false : true
              "
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ["dataPropsHas"],
  data() {
    return {};
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {},
  created() {},
  computed: {
    isSpecialist() {
      return this.moduleId == 11 && this.moduleId == 25;
    },
    isModulSpecialist() {
      return this.moduleId == 11 ? true : false;
    },
    isModulSpecialistsDigital() {
      return this.moduleId == 25 ? true : false;
    },
  },
};
</script>
