var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"width":"48%"},attrs:{"variant":"light"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("RECOMMENDATIONs")])],1),_c('b-input-group-append',{staticStyle:{"display":"grid","width":"52%"},attrs:{"is-text":""}},[_c('b-form-checkbox-group',{staticClass:"w-100",attrs:{"disabled":_vm.isModulSpecialistsDigital &&
                    _vm.dataProps.statuss == 'PENDING'
                      ? false
                      : _vm.isModulSpecialist
                      ? false
                      : true},model:{value:(_vm.formLetter.recommendation),callback:function ($$v) {_vm.$set(_vm.formLetter, "recommendation", $$v)},expression:"formLetter.recommendation"}},[_c('b-form-checkbox',{attrs:{"value":1}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("NCR")])]),_c('b-form-checkbox',{attrs:{"value":2}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Application")])])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"class-campo-text col-md-12",staticStyle:{"border":"none","padding":"0"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
                      var valid = ref.valid;
return [_c('b-form-textarea',{staticClass:"mt-1",style:(_vm.formLetter.textarea_error ? 'border-color: red;' : ''),attrs:{"placeholder":"Enter something...","disabled":_vm.isModulSpecialistsDigital &&
                    _vm.dataProps.statuss == 'PENDING'
                      ? false
                      : _vm.isModulSpecialist
                      ? false
                      : true,"state":errors[0] ? false : valid ? true : null},on:{"input":_vm.validateInput},model:{value:(_vm.formLetter.textarea),callback:function ($$v) {_vm.$set(_vm.formLetter, "textarea", $$v)},expression:"formLetter.textarea"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])],1),(_vm.formLetter.recommendation.includes(2))?_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"width":"48%"},attrs:{"variant":"light"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("TYPE OF APPLICATION:")])],1),_c('b-form-select',{staticClass:"class-campo-text input-form",attrs:{"disabled":_vm.isModulSpecialistsDigital && _vm.dataProps.statuss == 'PENDING'
                    ? false
                    : _vm.isModulSpecialist
                    ? false
                    : true},model:{value:(_vm.formLetter.type_application),callback:function ($$v) {_vm.$set(_vm.formLetter, "type_application", $$v)},expression:"formLetter.type_application"}},_vm._l((_vm.dataProps.charges),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])}),1)],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("COMMENTS")]),_c('b-form-textarea',{staticStyle:{"margin-top":"3px"},attrs:{"placeholder":"Enter something...","disabled":_vm.isModulSpecialistsDigital && _vm.dataProps.statuss == 'PENDING'
                  ? false
                  : _vm.isModulSpecialist
                  ? false
                  : true},model:{value:(_vm.formLetter.comments),callback:function ($$v) {_vm.$set(_vm.formLetter, "comments", $$v)},expression:"formLetter.comments"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }