var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"width":"48%"},attrs:{"variant":"light"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("RECOMMENDATION ")])],1),_c('b-input-group-append',{staticStyle:{"display":"grid","width":"52%"},attrs:{"is-text":""}},[_c('b-form-checkbox-group',{staticClass:"w-100",attrs:{"disabled":_vm.isModulSpecialistsDigital &&
                  _vm.dataPropsRecovery.statuss == 'PENDING'
                    ? false
                    : _vm.isModulSpecialist
                    ? false
                    : true},model:{value:(_vm.dataPropsRecovery.selectedRecommendations),callback:function ($$v) {_vm.$set(_vm.dataPropsRecovery, "selectedRecommendations", $$v)},expression:"dataPropsRecovery.selectedRecommendations"}},[_c('b-form-checkbox',{attrs:{"value":1}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("NCR")])]),_c('b-form-checkbox',{attrs:{"value":2}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Application")])])],1)],1)],1)],1)],1),_c('b-row',[(_vm.dataPropsRecovery.selectedRecommendations.includes(2))?_c('b-col',{staticClass:"pt-2",attrs:{"md":"12"}},[_c('b-input-group',[_c('b-input-group-prepend',{staticStyle:{"width":"48%"},attrs:{"variant":"light"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("TYPE OF APPLICATION:")])],1),_c('b-form-select',{staticClass:"class-campo-text input-form",style:(_vm.dataPropsRecovery.error_obs_charge == true
                  ? 'border-color: red;'
                  : ''),attrs:{"disabled":_vm.isModulSpecialistsDigital &&
                _vm.dataPropsRecovery.statuss == 'PENDING'
                  ? false
                  : _vm.isModulSpecialist
                  ? false
                  : true},model:{value:(_vm.dataPropsRecovery.idcharge),callback:function ($$v) {_vm.$set(_vm.dataPropsRecovery, "idcharge", $$v)},expression:"dataPropsRecovery.idcharge"}},_vm._l((_vm.dataPropsRecovery.charges),function(item,index){return _c('b-form-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.description)+" ")])}),1)],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("RESULTS:")]),_c('b-form-textarea',{staticStyle:{"margin-top":"3px"},style:(_vm.formRecov.results_error == true ? 'border-color: red;' : ''),attrs:{"placeholder":"Enter something...","disabled":_vm.isModulSpecialistsDigital &&
              _vm.dataPropsRecovery.statuss == 'PENDING'
                ? false
                : _vm.isModulSpecialist
                ? false
                : true},on:{"input":function($event){return _vm.validateInput()}},model:{value:(_vm.formRecov.results),callback:function ($$v) {_vm.$set(_vm.formRecov, "results", $$v)},expression:"formRecov.results"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("COMMENTS:")]),_c('b-form-textarea',{staticStyle:{"margin-top":"3px"},style:(_vm.formRecov.comments_error == true ? 'border-color: red;' : ''),attrs:{"placeholder":"Enter something...","disabled":_vm.isModulSpecialistsDigital &&
              _vm.dataPropsRecovery.statuss == 'PENDING'
                ? false
                : _vm.isModulSpecialist
                ? false
                : true},on:{"input":function($event){return _vm.validateInput()}},model:{value:(_vm.formRecov.comments),callback:function ($$v) {_vm.$set(_vm.formRecov, "comments", $$v)},expression:"formRecov.comments"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"w-100 disableBtn",attrs:{"variant":_vm.isDarkSkin ? 'primary' : 'light'}},[_vm._v("NEXT STEPS:")]),_c('b-form-textarea',{staticStyle:{"margin-top":"3px"},style:(_vm.formRecov.next_error == true ? 'border-color: red;' : ''),attrs:{"placeholder":"Enter something...","disabled":_vm.isModulSpecialistsDigital &&
              _vm.dataPropsRecovery.statuss == 'PENDING'
                ? false
                : _vm.isModulSpecialist
                ? false
                : true},on:{"input":function($event){return _vm.validateInput()}},model:{value:(_vm.formRecov.next),callback:function ($$v) {_vm.$set(_vm.formRecov, "next", $$v)},expression:"formRecov.next"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }