<template>
  <div>
    <filter-slot
      class="border-top-primary border-3 border-table-radius p-0 m-0"
      :filter="filterSlot.filters"
      :filterSearch="filterSlot.filterSearch"
      :paginate="filterSlot.paginate"
      :totalRows="filterSlot.totalRows"
      :startRow="filterSlot.startRow"
      :endRow="filterSlot.endRow"
      @reload="$refs['updatesChargeListTable'].refresh()"
    >
      <!--:::ini table::::-->
      <b-table
        small
        slot="table"
        no-provider-filtering
        ref="updatesChargeListTable"
        :items="myProvider"
        :fields="filteredFields"
        primary-key="id"
        table-class="text-nowrap"
        responsive="sm"
        show-empty
        :busy.sync="isBusy"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
      >
        <!--::::INI LOADING:::::-->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <!--::::END LOADING:::::-->

        <template #cell(lead_name)="data">
          <div class="d-flex">
            <status-account :account="data.item" :text="false"></status-account>
            <a
              :href="
                '/specialistdigital/clients/account/' + data.item.account_id
              "
            >
              {{ data.item.lead_name }}
            </a>
          </div>
          {{ data.item.account }}
          <badge-risky-clients
            :idAccount="data.item.client_account_id"
            :riskLevel="data.item.risk_level"
            :riskDescription="data.item.risk_description"
            :riskType="data.item.risk_type"
          />
        </template>

        <template #cell(type_client)="data">
          <span>{{ data.item.name_type_client }}</span>
        </template>

        <template #cell(title)="data">
          <div
            class="cursor-pointer text-primary"
            @click="openModalAddRound(data.item)"
          >
            {{ data.item.title }}
          </div>
        </template>

        <template #cell(last_score_date)="data">
          {{ data.item.last_score_date | myGlobalDay }}
        </template>

        <template #cell(cr)="data">
          <a
            :href="
              '/specialistdigital/leads/report/' +
              data.item.lead_id +
              '/' +
              (data.item.score_id || data.item.last_score) +
              '/' +
              data.item.client_account_id
            "
            target="_blank"
            style="cursor: pointer"
          >
            <feather-icon
              size="20"
              :class="isDarkSkin ? 'text-primary' : 'text-dark'"
              icon="FileTextIcon"
            />
          </a>
        </template>

        <template #cell(request_by)="data">
          {{ data.item.user_created }}
          <br />
          {{ data.item.create_dispute | myGlobalDay }}
        </template>

        <template #cell(completed_by)="data">
          {{ data.item.userrecommendation }}
          <br />
          {{ data.item.created_recommendation | myGlobalDay }}
        </template>

        <template #cell(userrecommendation)="data">
          {{ data.item.userrecommendation }}
          <br />
          {{ data.item.created_recommendation | myGlobalDay }}
        </template>

        <template #cell(user_deleted)="data">
          {{ data.value }}
          <br />
          {{ data.item.deleted_at | myGlobalDay }}
        </template>

        <template #cell(status)="data">
          <span v-if="data.item.status == 'PENDING'" style="color: #e09900">{{
            data.item.status
          }}</span>
          <span v-if="data.item.status == 'COMPLETED'" style="color: #00cc00">{{
            data.item.status
          }}</span>
          <span v-if="data.item.status == 'DELETED'" style="color: #ff0000">{{
            data.item.status
          }}</span>
        </template>

        <template #cell(type)="data">
          {{ data.item.type == 1 ? "Letter" : "Recovery" }}
        </template>

        <template #cell(action)="{ item }">
          <feather-icon
            class="text-info cursor-pointer"
            v-b-tooltip.hover.left="'Update Request'"
            icon="FolderIcon"
            size="20"
            @click="
              openmodaltracking(
                item.account_id,
                item.id_credential,
                item.id,
                item.status,
                item.account,
                item.lead_name,
                item.type,
                item.observation,
                item.recommendation,
                item.commentary1,
                item.commentary2,
                item.commentary3,
                item.commentary4,
                item.commentary5,
                item.userrecommendation,
                item.created_recommendation,
                item.type_app,
                item.option_recommendation
              )
            "
          />
          <a
            href="javascript:;"
            @click="deleteRoundLetterDispute(item.id)"
            class="ml-1"
            v-if="isPending"
          >
            <feather-icon
              v-b-tooltip.hover.left="'Delete'"
              icon="TrashIcon"
              size="20"
            />
          </a>
        </template>
      </b-table>
      <!--:::fin table::::-->
    </filter-slot>

    <modal-update-request
      v-if="modalData.state"
      :modalData="modalData"
      :statuss="this.statuss"
      :idround="this.idround"
      :account="this.account"
      :nameClient="this.nameClient"
      :credential="this.credential"
      :typee="this.typee"
      :account_id="this.account_id"
      :type_charge="this.type_charge"
      :option_recommendations="this.option_recommendations"
      :recommen="this.recommen"
      :observationData="this.observation"
    ></modal-update-request>

    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :show-all-letters="false"
      @close="modalAddRoundLetterState = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal.vue";
import DataFields from "../data/fields.data";
import UpdateService from "../services/update.service.js";
import DataFilters from "../data/filters.data.js";
import FilterSlot from "../slot/FilterSlot.vue";
import ModalUpdateRequest from "../components/modal/ModalUpdateRequest.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    FilterSlot,
    ModalUpdateRequest,
    BadgeRiskyClients,
    AddRoundModal,
    StatusAccount,
  },
  data() {
    return {
      modalData: {
        state: false,
        openModal: true,
      },

      modalDataFirst: {
        state: false,
        openModal: true,
      },

      fields: DataFields,
      isBusy: false,
      sortBy: "lead_name",
      program: 7,
      idround: "",

      statuss: "",
      recommen: "",

      users: [],

      account: "",
      nameClient: "",
      account_id: "",
      type_charge: null,

      fields: DataFields,
      isBusy: false,
      sortBy: "lead_name",
      program: 7,
      idround: "",
      option_recommendations: [],
      observation: "",
      filterSlot: {
        filters: DataFilters,
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 100,
        startRow: 1,
        endRow: 0,
      },
      itemClient: {},
      validateModal: 3,
      modalAddRoundLetterState: false,
    };
  },
  created() {
    this.allAdvisors();
  },
  methods: {
    async myProvider(ctx) {
      let orderBy = 8;
      let sortDirection = "asc";
      if (ctx.sortBy === "lead_name") {
        ctx.sortDesc ? (sortDirection = "desc") : (sortDirection = "asc");
        orderBy = 9;
      } else if (ctx.sortBy === "program") {
        ctx.sortDesc ? (sortDirection = "desc") : (sortDirection = "asc");
        orderBy = 6;
      } else if (ctx.sortBy === "request_by") {
        ctx.sortDesc ? (sortDirection = "desc") : (sortDirection = "asc");
        orderBy = 8;
      } else if (ctx.sortBy === "completed_by") {
        ctx.sortDesc ? (sortDirection = "desc") : (sortDirection = "asc");
        orderBy = 22;
      } else if (ctx.sortBy === "type_client") {
        ctx.sortDesc ? (sortDirection = "desc") : (sortDirection = "asc");
        orderBy = 41;
      }
      const params = {
        name_text:
          this.filterSlot.filterSearch.model == ""
            ? ""
            : this.filterSlot.filterSearch.model, ///ok
        program: this.program, ///ok
        from:
          this.filterSlot.filters[0].model == ""
            ? null
            : this.filterSlot.filters[0].model, ///ok filter calendar,
        to:
          this.filterSlot.filters[1].model == ""
            ? null
            : this.filterSlot.filters[1].model, ///ok filter calendar,
        orderby: orderBy, ///ok
        order: sortDirection, ///ok
        advisorid: null, ///ok
        userid: this.currentUser.role_id != 3 ? "" : this.currentUser.user_id, ///ok
        roleid: this.currentUser.role_id, ///ok
        status: this.$route.meta.tabId == 3 ? 1 : this.$route.meta.tabId, ///ok
        perPage: this.filterSlot.paginate.perPage, ///ok
        is_deleted: this.$route.meta.tabId === 3 ? 1 : 0, ///ok
        type:
          parseInt(this.filterSlot.filters[2].model) == 0
            ? null
            : parseInt(this.filterSlot.filters[2].model), ///ok
        f_specialist:
          parseInt(this.filterSlot.filters[3].model) == 0
            ? null
            : parseInt(this.filterSlot.filters[3].model), ///ok
        page: this.filterSlot.paginate.currentPage, ///ok
        is_digital: 2,
      };
      let result = [];
      result = await UpdateService.getPendingUpdates(params, ctx.currentPage);

      //filterSlot
      this.filterSlot.totalRows = result.total;
      this.filterSlot.startRow = result.from;
      this.filterSlot.endRow = result.to;
      return result.data || [];
    },
    openmodaltracking(
      accountid,
      creden,
      id,
      status,
      account,
      name,
      type,
      obs,
      commen0,
      commen1,
      commen2,
      commen3,
      commen4,
      commen5,
      user_recommendation,
      created_recommendation,
      type_charge,
      option_recommendations
    ) {
      this.statuss = status;
      this.idround = id;
      this.account = account;
      this.nameClient = name;
      this.credential = creden;
      this.typee = type;
      this.observation = obs;
      this.type_charge = type_charge;
      this.option_recommendations =
        option_recommendations === null ? [] : option_recommendations;
      this.recommen = [
        commen0,
        commen1,
        commen2,
        commen3,
        commen4,
        commen5,
        user_recommendation,
        created_recommendation,
      ];
      this.account_id = accountid;
      this.modaltracking = true;
      this.modalData.state = true;
      this.modalData.openModal = true;
    },
    async deleteRoundLetterDispute(id_dispute) {
      const result = await this.showConfirmSwal();
      try {
        if (result.isConfirmed) {
          let params = {
            user_id: this.currentUser.user_id,
            id_dispute: id_dispute,
          };
          const reponse = await UpdateService.deleteRoundLetterDispute(params);
          if (reponse.status === 200) {
            this.$swal
              .fire("Deleted!", "It has been deleted.", "success")
              .then((res) => {
                if (res) {
                  this.$refs.updatesChargeListTable.refresh();
                }
              });
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    refreshTable() {
      //this.$refs['updatesChargeListTable'].refresh()
    },
    async allAdvisors() {
      var module = -1;
      if (this.program == 0) {
        module = 0;
      } else {
        switch (parseInt(this.program)) {
          case 1:
            module = 3;
            break;
          case 2:
            module = 7;
            break;
          case 3:
            module = 6;
            break;
          case 4:
            module = 5;
            break;
          case 5:
            module = 8;
            break;
          case 6:
            module = 10;
            break;
          case 7:
            module = 11;
            break;
        }
      }
      this.advisor = null;

      let result = [];
      const params = {
        module_id: module,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      result = await UpdateService.getUsersProgramsspNcr(params);
      this.users = result.data;
      var newList = this.users.map(
        (s) => ({ label: "All", value: 0 }, { label: s.user_name, value: s.id })
      );
      newList.splice(0, 0, { label: "All", value: 0 }); //add position 0
      this.filterSlot.filters[3].options = newList;
    },
    openModalAddRound(data) {
      this.itemClient = {
        id: data.ncr_letters_id,
        idAccount: data.client_account_id,
        account: data.account,
        clientName: data.lead_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          itin: data.itin,
          other: data.other,
          origin_country: data.origin_country,
        },
        isDirect: {
          id: data.letter_id,
          title: data.title,
          format: data.format,
        },
      };
      this.modalAddRoundLetterState = true;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    getTab() {
      return this.$route.meta.typeTab;
    },
    filteredFields() {
      this.fields[10].visible =
        this.getTab == "Pending" || this.getTab == "Completed" ? true : false;
      this.fields[9].visible = this.getTab == "Deleted" ? true : false;
      this.fields[6].visible = this.getTab == "Completed" ? true : false;
      return this.fields.filter((field) => field.visible);
    },
    isPending() {
      return this.$route.meta.typeTab === "Pending";
    },
  },
};
</script>
