export default [
  {
    key: "lead_name",
    label: "Client",
    visible: true,
    sortable: true,
  },
  {
    key: "name_type_client",
    label: "Type of Client",
    visible: false,
    sortable: true,
  },
  {
    key: "program",
    label: "Program",
    visible: true,
    sortable: true,
  },
  {
    key: "title",
    label: "Round",
    visible: true,
    sortable: true,
  },
  {
    key: "cr",
    label: "CR",
    visible: true,
  },
  {
    key: "request_by",
    label: "Request By",
    visible: true,
    sortable: true,
  },
  {
    key: "userrecommendation",
    label: "Completed By",
    visible: true,
    sortable: false,
  },
  {
    key: "status",
    label: "Status",
    visible: true,
  },
  {
    key: "type",
    label: "TYPE",
    visible: true,
  },
  {
    key: "user_deleted",
    label: "DELETED BY",
    visible: false,
  },
  {
    key: "action",
    label: "Action",
    visible: false,
  },
];
