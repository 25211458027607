<template>
  <div>
    <!--:::::::INI LETTER:::::::::-->
    <validation-observer ref="refFormObserver">
      <b-row>
        <b-col>
          <!--::::RECOMMENDATION::::-->
          <b-row>
            <b-col md="12">
              <b-input-group>
                <b-input-group-prepend style="width: 48%" variant="light">
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    >RECOMMENDATIONs</b-button
                  >
                </b-input-group-prepend>

                <b-input-group-append is-text style="display: grid; width: 52%">
                  <b-form-checkbox-group
                    class="w-100"
                    :disabled="
                      isModulSpecialistsDigital &&
                      dataProps.statuss == 'PENDING'
                        ? false
                        : isModulSpecialist
                        ? false
                        : true
                    "
                    v-model="formLetter.recommendation"
                  >
                    <b-form-checkbox :value="1"
                      ><span style="margin-left: 5px"
                        >NCR</span
                      ></b-form-checkbox
                    >
                    <b-form-checkbox :value="2"
                      ><span style="margin-left: 5px"
                        >Application</span
                      ></b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!--::::-->
          <b-row>
            <b-col md="12">
              <div
                class="class-campo-text col-md-12"
                style="border: none; padding: 0"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  rules="required"
                >
                  <b-form-textarea
                    class="mt-1"
                    v-model="formLetter.textarea"
                    :style="
                      formLetter.textarea_error ? 'border-color: red;' : ''
                    "
                    placeholder="Enter something..."
                    :disabled="
                      isModulSpecialistsDigital &&
                      dataProps.statuss == 'PENDING'
                        ? false
                        : isModulSpecialist
                        ? false
                        : true
                    "
                    :state="errors[0] ? false : valid ? true : null"
                    @input="validateInput"
                  ></b-form-textarea>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="formLetter.recommendation.includes(2)">
            <!--::::TYPE OF APPLICATION::::-->
            <b-col md="12" class="mt-2">
              <b-input-group>
                <b-input-group-prepend style="width: 48%" variant="light">
                  <b-button
                    :variant="isDarkSkin ? 'primary' : 'light'"
                    class="w-100 disableBtn"
                    >TYPE OF APPLICATION:</b-button
                  >
                </b-input-group-prepend>
                <b-form-select
                  :disabled="
                    isModulSpecialistsDigital && dataProps.statuss == 'PENDING'
                      ? false
                      : isModulSpecialist
                      ? false
                      : true
                  "
                  class="class-campo-text input-form"
                  v-model="formLetter.type_application"
                >
                  <b-form-select-option
                    v-for="(item, index) in dataProps.charges"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.description }}
                  </b-form-select-option>
                </b-form-select>
              </b-input-group>
            </b-col>

            <!--::::COMMENTS::::-->
            <b-col md="12" class="mt-2">
              <b-button
                :variant="isDarkSkin ? 'primary' : 'light'"
                class="w-100 disableBtn"
                >COMMENTS</b-button
              >
              <b-form-textarea
                style="margin-top: 3px"
                placeholder="Enter something..."
                v-model="formLetter.comments"
                :disabled="
                  isModulSpecialistsDigital && dataProps.statuss == 'PENDING'
                    ? false
                    : isModulSpecialist
                    ? false
                    : true
                "
              ></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <!--:::::::END LETTER:::::::::-->
  </div>
</template>
<script>
export default {
  props: ["dataProps", "formLetter"],
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.validateInput();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModulSpecialist() {
      return this.moduleId == 11 ? true : false;
    },
    isModulSpecialistsDigital() {
      return this.moduleId == 25 ? true : false;
    },
  },
  methods: {
    validateInput() {
      this.formLetter.textarea.length > 0
        ? (this.formLetter.textarea_error = false)
        : (this.formLetter.textarea_error = true);
    },
  },
};
</script>
