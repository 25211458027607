<template>
  <div>
    <b-modal
      v-model="modalData.openModal"
      size="lg"
      title-class="h3 text-white font-weight-bolder"
      title="UPDATE REQUEST"
      header-class="b-vue-modal-header"
      modal-class="top-modal"
      hide-footer
      no-close-on-backdrop
      @hidden="hideModal"
    >
      <div class="px-1">
        <b-row>
          <b-col sm="12" md="6" lg="6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 40%">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  readonly
                  role="text"
                  >ACCOUNT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="account"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>

          <b-col sm="12" md="6" lg="6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 40%">
                <b-button
                  :variant="isDarkSkin ? 'primary' : 'light'"
                  class="w-100 disableBtn"
                  readonly
                  >CLIENT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="nameClient"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <div
        class="px-1"
        style="
          max-height: 75vh;
          overflow-y: scroll;
          overflow-x: hidden;
          margin-top: 5px;
        "
      >
        <b-row>
          <b-col sm="12" md="12" lg="6">
            <validation-observer ref="form">
              <b-row class="mt-2">
                <b-col sm="12" md="12" lg="5">
                  <h5>Select:</h5>
                  <b-select
                    v-model="select_request"
                    @change="credentialemail()"
                    :disabled="
                      isSpecialist && ['PENDING', 'COMPLETED'].includes(statuss)
                        ? true
                        : false
                    "
                    style="color: rgb(102, 102, 102) !important; height: 30px"
                    :style="
                      error_select_request == true ? 'border-color: red;' : ''
                    "
                  >
                    <b-form-select-option value="1"
                      >LETTER</b-form-select-option
                    >
                    <b-form-select-option value="2"
                      >RECOVERY</b-form-select-option
                    >
                    <b-form-select-option v-if="select_request == 3" value="3"
                      >NCR EXPIRED</b-form-select-option
                    >
                  </b-select>
                </b-col>
                <b-col sm="12" md="12" lg="7">
                  <div v-show="emailview == true" class="mt-sm-2 mt-lg-0">
                    <h5>Email of access credentials:</h5>
                    <b-form-select
                      class="input-form"
                      v-model="select_email"
                      style="color: rgb(102, 102, 102) !important; height: 30px"
                      :disabled="
                        isSpecialist &&
                        ['PENDING', 'COMPLETED'].includes(statuss)
                          ? true
                          : false
                      "
                      :style="
                        error_select_email == true ? 'border-color: red;' : ''
                      "
                    >
                      <b-form-select-option :value="null" disabled="disabled"
                        >Select Request</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(item, index) in dataemail"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.user }}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col sm="12" md="12" lg="12">
                  <b-input-group>
                    <b-input-group-prepend variant="light" style="width: 40%">
                      <b-button
                        :variant="isDarkSkin ? 'primary' : 'light'"
                        class="w-100 disableBtn"
                        readonly
                        >OBSERVATION</b-button
                      >
                    </b-input-group-prepend>

                    <b-form-select
                      v-if="select_request == 2"
                      v-model="obsSelected"
                      @change="textObservation"
                      :style="
                        error_select_request == true ? 'border-color: red;' : ''
                      "
                      :disabled="
                        isSpecialist &&
                        ['PENDING', 'COMPLETED'].includes(statuss)
                          ? true
                          : false
                      "
                    >
                      <b-form-select-option :value="null" disabled="disabled"
                        >Select Observation</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="(option, i) in observationOptions"
                        :key="i"
                        :value="option.value"
                        >{{ option.text }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-input-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12" md="12" lg="12">
                  <b-form-textarea
                    class="my-2"
                    v-model="obs"
                    placeholder="Enter something..."
                    :disabled="this.disabled"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </validation-observer>
          </b-col>

          <b-col sm="12" md="12" lg="6" class="mt-3">
            <div
              :style="
                dispute != null
                  ? dispute.length >= 4
                    ? 'height: 370px;overflow:auto;width:100%'
                    : ''
                  : ''
              "
            >
              <b-table
                small
                slot="table"
                no-provider-filtering
                :items="dispute"
                :fields="fields"
                primary-key="id"
                table-class="text-nowrap"
                responsive="sm"
                show-empty
                sticky-header="70vh"
              >
                <template #cell(name)="data">
                  <a :href="data.item.url" target="_blank">
                    {{ data.item.file_name }}
                  </a>
                </template>

                <template #cell(size)="data">
                  {{ data.item.size }}
                </template>

                <template #cell(bureu)="data">
                  <b-img
                    v-if="data.item.bureau"
                    :src="'/assets/images/icons/' + data.item.bureau + '.png'"
                  />
                </template>

                <template #cell(upload_at)="data">
                  {{ data.item.created_at | myGlobalDay }}
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <!--:::::::INI LETTER:::::::::-->
        <b-row v-if="letterDataProps.active_comment">
          <b-col>
            <LetterComponent
              :dataProps="letterDataProps"
              :formLetter="formLetter"
            ></LetterComponent>
          </b-col>
        </b-row>
        <!--:::::::END LETTER:::::::::-->

        <!--::::::::::INI:::::::::::::-->
        <b-row v-else-if="!letterDataProps.active_comment && hasObservations">
          <b-col>
            <HasObservations :dataPropsHas="letterDataProps"></HasObservations>
          </b-col>
        </b-row>
        <!--::::::::::END:::::::::::::-->

        <!--::::::INI RECOVERY::::::::-->
        <b-row v-else>
          <b-col>
            <RecoveryComponent
              :dataPropsRecovery="letterDataProps"
              :formRecov="formRecov"
            ></RecoveryComponent>
          </b-col>
        </b-row>
        <!--::::::END RECOVERY::::::::-->
      </div>

      <b-row>
        <div class="offset-4 col-4 text-center">
          <b-button
            variant="success"
            class="rounded mt-2 mb-1"
            @click="updateDispute()"
          >
            <i class="fas fa-save"></i> COMPLETED
          </b-button>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UpdateService from "../../services/update.service.js";
import LetterComponent from "./componentLetter.vue";
import RecoveryComponent from "./componentRecovery.vue";
import HasObservations from "./componentHasObservations.vue";
import DataFieldsUpdateRequest from "../../data/fields.data.updateRequest";

export default {
  props: [
    "modalData",
    "statuss",
    "idround",
    "account",
    "nameClient",
    "credential",
    "typee",
    "recommen",
    "account_id",
    "type_charge",
    "option_recommendations",
    "observationData",
  ],
  components: {
    LetterComponent,
    RecoveryComponent,
    HasObservations,
  },
  data() {
    return {
      letterDataProps: {
        active_comment: false,
        selectedRecommendations: [],
        statuss: this.statuss,
        obs0: "",
        obs1: "",
        obs2: "",
        obs3: "",
        obs4: "",
        idcharge: this.type_charge,
        error_obs_0: false,
        error_obs_1: false,
        error_obs_2: false,
        error_obs_3: false,
        error_obs_charge: false,
        charges: 0,
        obs5: "",
      },

      dispute: [],
      obs: "",
      obsSelected: null,

      error_obs_4: false,
      error_obs_5: false,
      select_request: this.typee,
      error_select_request: false,
      emailview: false,
      dataemail: "",
      disabled: true,
      gifsearch: false,
      select_email: null,
      error_select_email: false,
      fields: DataFieldsUpdateRequest,

      formLetter: {
        recommendation: [],
        textarea: "",
        textarea_error: "",
        type_application: "",
        comments: "",
      },
      formRecov: {
        results: "",
        results_error: "",
        comments: "",
        comments_error: "",
        next: "",
        next_error: "",
      },
    };
  },
  created() {
    this.start();
    this.search();
    this.getcredentialemail();
    this.credentialemail();
    this.select_request == 1
      ? (this.fields[2] = true)
      : (this.fields[2] = false);
  },
  methods: {
    credentialemail() {
      this.textObservation();
      if (this.select_request == 2) {
        this.emailview = true;
        this.letterDataProps.active_comment = false;
      } else {
        this.emailview = false;
        this.letterDataProps.active_comment = true;
        this.obs = "PLEASE ANALIZE THE LETTERS";
      }
    },
    async getcredentialemail() {
      try {
        const response = await UpdateService.getCredentialEmail({
          client_id: this.account_id,
        });
        this.dataemail = response.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    textObservation() {
      if (this.select_request == 2) {
        switch (this.obsSelected) {
          case 1:
            this.obs = "LETTER WAS NOT RECEIVED";
            break;
          case 2:
            this.obs = "LETTER LOST OR STOLEN";
            break;
          case 3:
            this.obs = "LETTER NOT SENT";
            break;
          default:
            this.obs = this.observation;
            break;
        }
      }
    },
    async updateDispute() {
      if (this.select_request != 2) {
        if (this.formLetter.textarea_error) {
          return false;
        } else {
          const result = await this.showConfirmSwal();
          try {
            if (result.isConfirmed) {
              const response = await UpdateService.updateLettersDispute({
                dispute_id: this.idround,
                type: this.select_request,
                credential: this.select_request == 2 ? this.select_email : "",
                observation0: this.formLetter.textarea,
                observation1: this.letterDataProps.obs1,
                observation2: this.formLetter.comments,
                observation3: this.letterDataProps.obs3,
                observation4: this.letterDataProps.obs4,
                observation5: this.letterDataProps.obs5,
                user_id: this.currentUser.user_id,
                options_recommendation: this.formLetter.recommendation,
                typecharge: this.formLetter.type_application,
                client_account_id: this.account_id,
              });
              if (response) {
                this.$swal
                  .fire({
                    icon: "success",
                    title: "successfully",
                  })
                  .then((res) => {
                    if (this.isModulSpecialist) {
                      this.$parent.$refs["disputeResultsList"].refresh(); //table parent refresh
                    } else {
                      this.$parent.$refs["updatesChargeListTable"].refresh(); //table parent refresh
                    }
                    this.modalData.openModal = false;
                  });
              }
            }
          } catch (e) {
            this.showErrorSwal(e);
          }
        }
      } else {
        const resultForm = await this.$refs.form.validate();
        if (resultForm == false) {
          //form input required
          return false;
        }
        let valida_input = this.formRecov.results_error
          ? false
          : this.formRecov.comments_error
          ? false
          : this.formRecov.next_error
          ? false
          : true;
        if (valida_input) {
          const result = await this.showConfirmSwal();
          try {
            if (result.isConfirmed) {
              const response = await UpdateService.updateLettersDispute({
                dispute_id: this.idround,
                type: this.select_request,
                credential: this.select_request == 2 ? this.select_email : "",
                observation0: this.letterDataProps.obs0,
                observation1: this.formRecov.results,
                observation2: this.formRecov.comments,
                observation3: this.formRecov.next,
                observation4: this.letterDataProps.obs4,
                observation5: this.letterDataProps.obs5,

                user_id: this.currentUser.user_id,
                options_recommendation:
                  this.letterDataProps.selectedRecommendations,
                typecharge: this.letterDataProps.idcharge,
                client_account_id: this.account_id,
              });

              if (response) {
                this.$swal
                  .fire({ icon: "success", title: "successfully" })
                  .then((res) => {
                    if (this.isModulSpecialist) {
                      this.$parent.$refs["disputeResultsList"].refresh(); //table parent refresh
                    } else {
                      this.$parent.$refs["updatesChargeListTable"].refresh(); //table parent refresh
                    }
                    this.modalData.openModal = false;
                  });
              }
            }
          } catch (e) {
            this.showErrorSwal(e);
          }
        }
      }
    },
    hideModal() {
      this.modalData.openModal = false;
      this.modalData.state = false;
    },
    async search() {
      try {
        this.gifsearch = true;
        const response = await UpdateService.getAllLettersDispute({
          id: this.idround,
        });
        if (response) {
          this.dispute = response.data;
          this.gifsearch = false;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async getTypeCharges() {
      try {
        const response = await UpdateService.getTypeCharges();
        this.letterDataProps.charges = response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    idObservation() {
      if (this.select_request == 2) {
        this.obs = this.observation;
        switch (this.observation) {
          case "LETTER WAS NOT RECEIVED":
            this.obsSelected = 1;
            break;
          case "LETTER LOST OR STOLEN":
            this.obsSelected = 2;
            break;
          case "LETTER NOT SENT":
            this.obsSelected = 3;
            break;
          default:
            this.obsSelected = this.isModulSpecialist ? null : 0;
            break;
        }
      }
    },
    async start() {
      this.letterDataProps.selectedRecommendations = JSON.parse(
        this.option_recommendations
      );
      await this.getTypeCharges();
      this.client_name = this.nameClient;
      this.select_email = this.credential;
      this.obs = this.observation;
      this.letterDataProps.obs0 = this.recommen[0];
      this.letterDataProps.obs1 = this.recommen[1];
      this.letterDataProps.obs2 = this.recommen[2];
      this.letterDataProps.obs3 = this.recommen[3];
      this.letterDataProps.obs4 = this.recommen[4];
      this.letterDataProps.obs5 = this.recommen[5];
      this.userrecome = this.recommen[6];
      this.timecomen = this.recommen[7];
      this.letterDataProps.idcharge = this.type_charge;
      this.observation = this.observationData;
      this.idObservation();
    },
  },
  computed: {
    observationOptions() {
      return [
        { value: 1, text: "Letter was not received" },
        { value: 2, text: "Letter lost or stolen" },
        { value: 3, text: "Letter not sent" },
      ];
    },
    hasObservations() {
      return !!this.letterDataProps.obs5 || !!this.letterDataProps.obs0;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModulSpecialist() {
      return this.moduleId == 11 ? true : false;
    },
    isModulSpecialistsDigital() {
      return this.moduleId == 25 ? true : false;
    },
    isSpecialist() {
      return this.moduleId == 11 || this.moduleId == 25;
    },
  },
};
</script>
<style>
.disableBtn {
  cursor: default !important;
  pointer-events: none !important;
}
</style>
